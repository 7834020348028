/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable dot-notation */
import {
  AwsGenerationOutput,
  AwsResourceConfiguration,
} from '@amzn/alkimia-model';
import {
  Grid,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import React, { useState } from 'react';
import { DEFAULT_TABLE_ITEM } from '../tableConfig';
import { AlkimiaAdvancedOptionsComponent } from './AdvancedOptions';
import { ResourceGroupingSideNavComponent } from './ResourceGroupingSideNav';
import { AlkimiaTableComponent } from './ResourceSelectionTable';
import { ItemType, SelectedOption } from './Types';

type AlkimiaResourceSelectionProps = {
  token: CognitoToken,
  accountLabel: string,
  regionID: string,
  listResources: AwsResourceConfiguration[],
  errorMessages: string[],
  setIsToolsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setAwsGenerationOutput: React.Dispatch<React.SetStateAction<AwsGenerationOutput>>,
}

/**
 * This is the selection page component where users may select which resources they would like to
 * to generate CDK code
 * @param token user token to call generateAwsIaC api after user has selected their resources
 * @param accountLabel Account information, will be used to parse and get TargetAccountId
 * @param regionID Region Selected by customer from Home page
 * @param listResources List of Resources after selecting an aws account
 * @param errorMessages Any error messages when listing/describing resources
 * @function setAwsGenerationOutput sets global state of generated output so generation page can display
 * @returns Selection page component
 */
function AlkimiaResourceSelectionComponent({
  token,
  accountLabel,
  regionID,
  listResources,
  errorMessages,
  setIsToolsOpen,
  setAwsGenerationOutput,
}: AlkimiaResourceSelectionProps) {
  const [isVerifiedResourcesToggle, setVerifiedResourcesToggle] = useState(true);
  const [clusters, setClusters] = useState(new Map<string, ItemType[]>());
  const [allTableItems, setAllTableItems] = useState<ItemType[]>([DEFAULT_TABLE_ITEM]);
  const [targetAccountID, settargetAccountID] = useState(accountLabel.split(':')[0]);
  const [clusteredListCheckState, setClusteredListCheckState] = useState<boolean[]>(([]));
  const [tableItems, setTableItems] = useState([DEFAULT_TABLE_ITEM]);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [sortedListResources, setSortedListResources] = useState<AwsResourceConfiguration[]>([]);
  const [selectAllClusters, setSelectAllClusters] = useState<boolean>(false);
  const [selectedTargetRegion, setselectedTargetRegion] = useState<SelectedOption>({
    label: 'us-east-1 (N.Virginia)',
    value: 'us-east-1',
  });

  return (
    <SpaceBetween size="s">
      <AlkimiaAdvancedOptionsComponent
        selectedItems={selectedItems}
        targetAccountID={targetAccountID}
        selectedTargetRegion={selectedTargetRegion}
        token={token}
        clusters={clusters}
        accountLabel={accountLabel}
        regionID={regionID}
        errorMessages={errorMessages}
        settargetAccountID={settargetAccountID}
        setselectedTargetRegion={setselectedTargetRegion}
        setAwsGenerationOutput={setAwsGenerationOutput}
      />
      <Grid
        gridDefinition={(!isVerifiedResourcesToggle ? [{ colspan: 3 }, { colspan: 9 }] : [{ colspan: 12 }])}
      >
        {!isVerifiedResourcesToggle
          && (
            <ResourceGroupingSideNavComponent
              clusteredListCheckState={clusteredListCheckState}
              allTableItems={allTableItems}
              clusters={clusters}
              selectAllClusters={selectAllClusters}
              setIsToolsOpen={setIsToolsOpen}
              setClusteredListCheckState={setClusteredListCheckState}
              setTableItems={setTableItems}
              setSelectAllClusters={setSelectAllClusters}
            />
          )}
        <AlkimiaTableComponent
          token={token}
          tableItems={tableItems}
          selectedItems={selectedItems}
          listResources={listResources}
          allTableItems={allTableItems}
          isVerifiedResourcesToggle={isVerifiedResourcesToggle}
          setSelectedItems={setSelectedItems}
          setSortedListResources={setSortedListResources}
          setTableItems={setTableItems}
          setAllTableItems={setAllTableItems}
          setClusters={setClusters}
          setClusteredListCheckState={setClusteredListCheckState}
          setVerifiedResourcesToggle={setVerifiedResourcesToggle}
        />
      </Grid>
    </SpaceBetween>
  );
}

export const AlkimiaResourceSelection = AlkimiaResourceSelectionComponent;
