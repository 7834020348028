import React, { ReactElement } from 'react';

export const SelectionPageHelp = (): ReactElement => (
  <div>
    <p>
      After selecting at least one resource to export, you can click
      {' '}
      <b>Generate CDK template</b>
      {' '}
      . You can also update the Advanced Options to customize the Target Account and Region for the generated CDK code.
    </p>
    <p>
      The
      {' '}
      <b>Discovered Resources</b>
      {' '}
      panel displays all the resources discovered from your account.
      {' '}
      You can filter the list by typing matching text or select filter on below two fields:
    </p>
    <ul>
      <li>
        <b>Construct Type Supported:</b>
        {' '}
        Alkimia supports all resources with L1, and some with L2 CDK constructs.
        {' '}
        To learn more on CDK Construct types, please refer to
        {' '}
        <a href="https://docs.aws.amazon.com/cdk/v2/guide/constructs.html">CDK Construct</a>
        {' '}
      </li>
      <li>
        <b>User Created:</b>
        {' '}
        Many resources are not created by you for any services, such as ones bootstrapped with account creation.
        {' '}
        We tried to flag the non-service related resources to make it easier to select needed resources.
        {' '}
        This field is only suggestive, and please let us know if we have flagged it wrong so we keep improving.
      </li>
      <li>
        <b>Resources Grouped by Dependency:</b>
        {' '}
        Resources Clustered by Dependency represent resources that are inter-related - they are all nodes on a shared graph.
        {' '}
        Because these resources reference each other we have clustered them together to help builders identify what roles these resource play in supporting your service and to simplify the process of selecting resources for CDK modeling.
        {' '}
        Builders can select entire clusters for modeling, or any combination of individual resources within a cluster.
      </li>
    </ul>
  </div>
);
