import { DeploymentStage } from './Constants';

export const FEATURE_TYPE_ACCOUNT_ID = {
  [DeploymentStage.LOCAL]: true,
  [DeploymentStage.BETA]: true,
  [DeploymentStage.GAMMA]: false,
  [DeploymentStage.PROD]: false,
};

export const FEATURE_DEPENDENCY_MODELING = {
  [DeploymentStage.LOCAL]: true,
  [DeploymentStage.BETA]: true,
  [DeploymentStage.GAMMA]: false,
  [DeploymentStage.PROD]: false,
};

export const FEATURE_AWS_RUM = {
  [DeploymentStage.LOCAL]: false,
  [DeploymentStage.BETA]: true,
  [DeploymentStage.GAMMA]: false,
  [DeploymentStage.PROD]: false,
};

export const FEATURE_USE_CASE_SELECTION = {
  [DeploymentStage.LOCAL]: true,
  [DeploymentStage.BETA]: true,
  [DeploymentStage.GAMMA]: true,
  [DeploymentStage.PROD]: true,
};

export const FEATURE_COVERAGE_ANALYSIS = {
  [DeploymentStage.LOCAL]: true,
  [DeploymentStage.BETA]: true,
  [DeploymentStage.GAMMA]: false,
  [DeploymentStage.PROD]: false,
};
