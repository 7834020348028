import {
  AwsGenerationInput,
  AwsGenerationOutput,
  ConstructType,
  OutputFormat,
} from '@amzn/alkimia-model';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import axios from 'axios';
import { getLambdaApiUrl, getStandardHeader, processApiError } from '../../utils';

const mock = false;

export const userSelection = async (
  token: CognitoToken,
  awsGenerationInput: AwsGenerationInput,
): Promise<AwsGenerationOutput> => {
  if (mock) { return Promise.resolve(mockResponse); }

  return axios.post(`${getLambdaApiUrl()}/aws/generateAwsIaCv2`,
    awsGenerationInput,
    { headers: getStandardHeader(token) })
    .then((result) => result.data)
    .catch((error) => processApiError('generateAwsIaC', error));
};

const mockResponse: AwsGenerationOutput = {
  awsAccountId: '1234567890',
  awsRegion: 'us-east-1',
  targetAwsAccountID: '1234567890',
  targetAwsRegion: 'us-east-1',
  cdkConstructType: ConstructType.DEFAULT,
  outputFormat: OutputFormat.JSON,
  outputFiles: [],
  outputZipFiles: [],
  messages: ['test error message1'],
};
