import axios from 'axios';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import { constructQueryString, getLambdaApiUrl, getStandardHeader, processApiError } from '../../utils';

const mock = false;

export const getAwsAccountName = async (token: CognitoToken, awsAccountId: string): Promise<string> => {
  if (mock) {
    return Promise.resolve(mockResponse);
  }

  return axios.get(`${getLambdaApiUrl()}/conduit/getAwsAccount?${constructQueryString({ awsAccountId })}`,
    { headers: getStandardHeader(token) })
    .then((result) => result.data.account?.name)
    .catch((err) => processApiError('getAwsAccount', err, true));
};

const mockResponse = 'Alkimia-TestCDK';
