import axios from 'axios';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import {
  constructQueryString,
  getLambdaApiUrl,
  getStandardHeader,
  processApiError,
} from '../../utils';

const mock = false;

export const getAwsAccountId = async (token: CognitoToken, awsAccountName: string): Promise<string> => {
  if (mock) {
    return Promise.resolve(mockResponse);
  }

  return axios.get(`${getLambdaApiUrl()}/conduit/findAwsAccount?${constructQueryString({ awsAccountName })}`,
    { headers: getStandardHeader(token) })
    .then((result) => result.data.identifier?.accountId)
    .catch((error) => processApiError('getAwsAccountId', error));
};

const mockResponse = '861399335178';
