import React, { ReactElement } from 'react';
import { Link } from '@amzn/awsui-components-react-v3';
import { FEATURE_TYPE_ACCOUNT_ID } from '../../utils/features';
import { getStage } from '../../utils';

export const HomePageHelp = (): ReactElement => (
  <div>
    <p>
      For now you need to grant Alkimia access to query your AWS account manually following
      {' '}
      <Link
        external
        externalIconAriaLabel="Opens in a new tab"
        href="https://w.amazon.com/bin/view/Alkimia/GrantAlkimiaAccessManually"
      >
        Grant Alkimia Access
      </Link>
      .
    </p>
    <p>
      Alkimia will NOT modify your resources and you can delete the IAM role
      {' '}
      <i>AlkimiaAccessRole</i>
      {' '}
      after using Alkimia.
    </p>
    <p>
      We will soon integrate with Conduit as a Trusted Service so you can enable Alkimia access with 1-click.
    </p>
    {FEATURE_TYPE_ACCOUNT_ID[getStage()] === true
      ? (
        <div>
          <p>
            <b>Type Account ID manually</b>
          </p>
          <p>
            By default, Alkimia will list all Conduit AWS accounts you can manage for you to select from.
            But you can also toggle the option to type in Account ID manually,
            in case the list does not include the Account, such as an Isengard account.
          </p>
        </div>
      )
      : null}
  </div>
);
