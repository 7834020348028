import { OutputFile } from '@amzn/alkimia-model';
import JSZip from 'jszip';

const download = (fileName: string, fileBlob: Blob) => {
  const anchorElement = document.createElement('a');
  anchorElement.download = fileName;
  anchorElement.href = URL.createObjectURL(fileBlob);
  document.body.appendChild(anchorElement);
  anchorElement.click();
  document.body.removeChild(anchorElement);
};

export const downloadFile = (fileName: string, fileContent: string) => {
  const fileBlob = new Blob([fileContent], { type: 'application/json;charset=utf-8;' });
  download(fileName, fileBlob);
};

export const downloadAll = (outputFiles: OutputFile[]) => {
  const zip = new JSZip();
  const libFolder = zip.folder('lib');
  outputFiles.forEach((outputFile) => {
    if (outputFile.type === 'config') {
      zip.file(outputFile.filename, new Blob([outputFile.payload], { type: 'application/json;charset=utf-8;' }));
    } else {
      libFolder?.file(outputFile.filename, new Blob([outputFile.payload], { type: 'application/json;charset=utf-8;' }));
    }
  });
  zip.generateAsync({ type: 'blob' }).then((blob) => {
    download('AlkimiaGeneratedCDK', blob);
  });
};
