import React from 'react';
import { AwsGenerationOutput } from '@amzn/alkimia-model';
import {
  Button,
  Container,
  Header,
  Popover,
  SpaceBetween,
  StatusIndicator,
  TextContent,
} from '@amzn/awsui-components-react-v3';
import { downloadAll, downloadFile } from '../../utils/downloadFile';
import './alkimia-output.scss';
import { displayErrorMessage } from '../../utils';

type AlkimiaOutputProps = {
  accountLabel: string
  awsRegion: string
  awsGenerationOutput: AwsGenerationOutput,
}

function AlkimiaOutputComponent({
  accountLabel,
  awsRegion,
  awsGenerationOutput,
}: AlkimiaOutputProps) {
  return (
    <SpaceBetween size="s">
      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <div style={{ flex: 1 }}>
          <Header
            variant="h2"
          >
            {`Generated CDK files for target account '${awsGenerationOutput.targetAwsAccountID}' and region '${awsGenerationOutput.targetAwsRegion}'`}
          </Header>
        </div>
        <div>
          <Button
            ariaLabel="Feedback"
            iconAlign="right"
            href="https://sim.amazon.com/issues/create?template=da0d26a2-abe4-439e-9f67-324ea1b57b01"
            variant="link"
            iconName="external"
            target="_blank"
          >
            Provide Feedback
          </Button>
        </div>
      </div>
      {displayErrorMessage(awsGenerationOutput.messages, 'Some resources from the above services may be missing, but feel free to proceed.')}
      <Container>
        <Header
          variant="h3"
          actions={
            (
              <Button
                iconAlign="right"
                variant="primary"
                onClick={() => {
                  downloadAll(awsGenerationOutput.outputFiles);
                }}
              >
                Download All
              </Button>
            )
          }
        >
          You can Download All for a zipped file of a CDK project, or Copy or Download each file.
        </Header>
        {
          awsGenerationOutput.outputFiles.map((item) => (
            <Container key={`${item.filename}`}>
              <SpaceBetween key={`${item.filename}`} size="s">
                <SpaceBetween direction="horizontal" size="s">
                  <TextContent>
                    {`${item.filename}`}
                  </TextContent>
                  <Popover
                    size="small"
                    position="top"
                    triggerType="custom"
                    dismissButton={false}
                    content={<StatusIndicator type="success">Output copied</StatusIndicator>}
                  >
                    <Button
                      iconName="copy"
                      onClick={() => {
                        navigator.clipboard.writeText(`${item.payload}`);
                      }}
                    >
                      Copy
                    </Button>
                  </Popover>
                  <Button
                    iconName="download"
                    onClick={() => {
                      downloadFile(`${item.filename}`, `${item.payload}`);
                    }}
                  >
                    Download
                  </Button>
                </SpaceBetween>
                <pre className="json-container">
                  {item.payload}
                </pre>
              </SpaceBetween>
            </Container>
          ))
        }
      </Container>
    </SpaceBetween>
  );
}

export const AlkimiaOutput = AlkimiaOutputComponent;
