import { AwsResourceConfiguration, ResourceManagementAnalysisInput, ResourceManagementAnalysisOutput } from '@amzn/alkimia-model';
import axios from 'axios';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import { getLambdaApiUrl, getStandardHeader, processApiError } from '../../utils';

const mock = false;

export const analyzeAwsResourceManagement = async (token: CognitoToken, configs: AwsResourceConfiguration[]): Promise<ResourceManagementAnalysisOutput> => {
  if (mock) {
    return Promise.resolve(mockResponse);
  }

  const input: ResourceManagementAnalysisInput = {
    resources: configs,
  };

  return axios.post(`${getLambdaApiUrl()}/aws/analyzeAwsResourceManagement`,
    input,
    { headers: getStandardHeader(token) })
    .then((result) => result.data)
    .catch((error) => processApiError('analyzeAwsResourceManagement', error));
};

const mockResponse: ResourceManagementAnalysisOutput = {
  coverageOverview: {
    accountId: '861399335178',
    accountRegion: 'us-east-1',
    totalResourceCount: 224 + 24 + 829 + 14 + 12 + 24,
    totalManagedByCloudFormation: 11 + 20 + 481 + 2 + 8 + 24,
    createdAt: new Date(Date.now()).toISOString(),
  },
  analysisByService: [{
    awsService: 'S3',
    resourceCount: 224,
    totalManagedByCloudformation: 118,
  },
  {
    awsService: 'CloudWatch',
    resourceCount: 24,
    totalManagedByCloudformation: 24,
  },
  {
    awsService: 'DynamoDB',
    resourceCount: 829,
    totalManagedByCloudformation: 481,
  },
  {
    awsService: 'EC2',
    resourceCount: 14,
    totalManagedByCloudformation: 2,
  },
  {
    awsService: 'CloudFront',
    resourceCount: 12,
    totalManagedByCloudformation: 8,
  },
  {
    awsService: 'AppConfig',
    resourceCount: 24,
    totalManagedByCloudformation: 24,
  }],
  messages: [],
};
