import React, { Component, ReactElement, ReactNode } from 'react';
import { Alert } from '@amzn/awsui-components-react';

interface ErrorBoundaryState {
  error: string;
}

interface ErrorBoundaryProps {
  login: string;
  children: ReactElement;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: '' };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error: error.message };
  }

  componentDidCatch(error: Error): void {
    console.log(this.props.login);
    console.log(error.name);
    console.log(error.message);
  }

  render(): ReactNode {
    const { children } = this.props;
    const { error } = this.state;
    if (error) {
      return (
        <div>
          <Alert
            header="An unexpected error has occured"
            type="error"
          >
            Please refresh the page.
          </Alert>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
