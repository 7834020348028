import { AwsResourceConfiguration, CoverageAnalysisSnapshot, ResourceManagementAnalysis } from '@amzn/alkimia-model';
import { Box, Button, ExpandableSection, Icon, PieChart, Popover, SpaceBetween, Spinner, TextContent } from '@amzn/awsui-components-react-v3';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import React, { useState } from 'react';
import { analyzeAwsResourceManagement } from '../../../api/calls/analyzeAwsResourceManagement';

type CoverageAnalysisOverviewProps = {
  token: CognitoToken,
  resourceConfigurations: AwsResourceConfiguration[],
}

export const CoverageAnalysisOverview = ({ token, resourceConfigurations }: CoverageAnalysisOverviewProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [analysisOverview, setAnalysisOverview] = useState<null | CoverageAnalysisSnapshot>(null);
  const [analysisByService, setAnalysisByService] = useState<ResourceManagementAnalysis[]>([]);
  return (
    <ExpandableSection headerText="View your infrastructure-as-code coverage analysis">
      {analysisOverview
        ? (
          <SpaceBetween size="s">
            <Box>
              <TextContent>
                <h2>
                  Account IaC Coverage Analysis (
                  {((analysisOverview.totalManagedByCloudFormation / analysisOverview.totalResourceCount) * 100).toFixed(0)}
                  % Coverage)
                  {' '}
                  <Popover
                    dismissButton={false}
                    position="right"
                    size="small"
                    triggerType="custom"
                    content={
                      <p>
                        Coverage percentage calculation: total amount of IaC managed resources / total amount of resources in account
                      </p>
                    }
                  >
                    <Button iconName="status-info" variant="inline-icon" />
                  </Popover>
                </h2>
                <p>
                  Total amount of discovered resources:
                  {' '}
                  <strong>
                    {analysisOverview.totalResourceCount}
                  </strong>
                </p>
                <p>
                  Total amount of discovered resources currently managed by CloudFormation:
                  {' '}
                  <strong>
                    {analysisOverview.totalManagedByCloudFormation}
                  </strong>
                </p>
              </TextContent>
            </Box>
            {analysisOverview.totalManagedByCloudFormation > 0 && <>
              <Box textAlign="center">
                <h2>IaC Coverage Analysis By Service</h2>
                <p>(Hover over sections for service analysis)</p>
              </Box>
              <PieChart
              hideFilter
              hideDescriptions
              hideTitles
              // hideLegend
              size="medium"
              variant="donut"
              innerMetricValue={`${analysisByService.length}`}
              innerMetricDescription="services"
              detailPopoverContent={(datum) => [
                {
                  key: 'AWS Service',
                  value: datum.title,
                },
                {
                  key: 'Total resource count',
                  value: datum.value,
                },
                {
                  key: 'Managed by CloudFormation',
                  value: datum.managed,
                },
                {
                  key: 'Coverage Percentage',
                  value: `${((datum.managed / datum.value) * 100).toFixed(0)}%`,
                },
              ]}
              detailPopoverSize="large"
              data={analysisByService.map((config) => ({
                title: config.awsService,
                value: config.resourceCount,
                managed: config.totalManagedByCloudformation,
              }))}
              />
              <Box textAlign="center">
                <p>**Proportions based on total resource count of each service</p>
              </Box>
            </>}
          </SpaceBetween>
        )
        : (
          <Button
            variant="primary"
            loading={isLoading}
            loadingText="Generating analysis..."
            onClick={async () => {
              try {
                setIsLoading(true);
                const result = await analyzeAwsResourceManagement(token, resourceConfigurations);
                setAnalysisOverview(result.coverageOverview);
                setAnalysisByService(result.analysisByService);
                setIsLoading(false);
              } catch (error) {
                setIsLoading(false);
                console.error(error);
              }
            }}
          >
            Generate Analysis
          </Button>
        )}
    </ExpandableSection>
  );
};
