import React, { ReactElement } from 'react';

export const OutputPageHelp = (): ReactElement => (
  <div>
    <p>
      This page displays all the files needed for a CDK project with the resources you have selected.
    </p>
    <p>
      You can copy each file or download all for a zipped file of a CDK project, then follow the
      {' '}
      <a href="https://w.amazon.com/bin/view/Alkimia/UserGuide/#H5.UsingAlkimiageneratedCDKartifacts">User Guide</a>
      {' '}
      to build and test the CDK artifacts.
    </p>
  </div>
);
