import React from 'react';
import { ConstructType } from '@amzn/alkimia-model';
import { CollectionPreferences, Header } from '@amzn/awsui-components-react-v3';
import { MAX_CLUSTER_NUMBER } from '../../utils/Constants';
import { ItemType } from './RelatedTable/Types';

export const DEFAULT_TABLE_ITEM: ItemType = {
  name: '', selected: false, type: '', service: '', supportedConstruct: ConstructType.DEFAULT, relevant: true, clusterId: '',
};

const getClusterId = (item): number => {
  if (item.clusterId === '') {
    return MAX_CLUSTER_NUMBER;
  }
  return Number(item.clusterId);
};

const comparator = (item1, item2): number => {
  const clusterId1 = getClusterId(item1);
  const clusterId2 = getClusterId(item2);
  if (clusterId1 === clusterId2) {
    return 0;
  } if (clusterId1 > clusterId2) {
    return 1;
  }
  return -1;
};

export const COLUMN_DEFINITIONS = [
  {
    id: 'name',
    header: 'Resource Name',
    cell: (resource) => resource.name,
    sortingField: 'name',
  },
  {
    id: 'type',
    header: 'Resource Type',
    cell: (resource) => resource.type,
    sortingField: 'type',
  },
  {
    id: 'service',
    header: 'Service',
    cell: (resource) => resource.service,
    sortingField: 'service',
  },
  {
    id: 'supportedConstruct',
    header: 'Construct Type Supported',
    cell: (resource) => resource.supportedConstruct,
    sortingField: 'supportedConstruct',
  },
  {
    id: 'relevant',
    header: 'User Created',
    cell: (resource) => (resource.relevant ? 'Yes' : 'No'),
    sortingField: 'relevant',
  },
  {
    id: 'clusterId',
    header: 'Cluster Id',
    cell: (resource) => (resource.clusterId),
    sortingComparator: comparator,
  },
  {
    id: 'managedByCfn',
    header: 'Managed by CloudFormation',
    cell: (resource) => (resource.managedByCfn ? 'Yes' : 'No'),
    sortingField: 'managedByCfn',
  },
];

export const COLUMN_DEFINITIONS_TEST = [
  {
    id: 'name',
    header: 'Resource Name',
    cell: (resource) => (resource.displayName ? resource.displayName : resource.name),
    sortingField: 'name',
  },
  {
    id: 'type',
    header: 'Resource Type',
    cell: (resource) => resource.type,
    sortingField: 'type',
  },
  {
    id: 'service',
    header: 'Service',
    cell: (resource) => resource.service,
    sortingField: 'service',
  },
  {
    id: 'supportedConstruct',
    header: 'Construct Type Supported',
    cell: (resource) => resource.supportedConstruct,
    sortingField: 'supportedConstruct',
  },
  {
    id: 'relevant',
    header: 'Service Related',
    cell: (resource) => (resource.relevant ? 'Yes' : 'No'),
    sortingField: 'relevant',
  },
  {
    id: 'clusterId',
    header: 'Cluster Id',
    cell: (resource) => (resource.clusterId),
    sortingComparator: comparator,
  },
];

const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Main Resource Description',
    options: [
      { id: 'name', label: 'Resource Name', editable: false },
      { id: 'type', label: 'Resource Type' },
      { id: 'service', label: 'Service' },
      { id: 'supportedConstruct', label: 'Construct Type Supported' },
      { id: 'relevant', label: 'Service Related' },
      { id: 'clusterId', label: 'Cluster Id' },
      { id: 'managedByCfn', label: 'Managed by CloudFormation' },
    ],
  },
];

export const SEARCHABLE_COLUMNS = ['name', 'type', 'service', 'supportedConstruct', 'relevant', 'clusterId', 'managedByCfn'];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Resources' },
  { value: 20, label: '20 Resources' },
  { value: 50, label: '50 Resources' },
  { value: 100, label: '100 Resources' },
  { value: 200, label: '200 Resources' },
  { value: 500, label: '500 Resources' },
  { value: 1000, label: '1000 Resources' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 1000,
  visibleContent: ['name', 'type', 'service', 'supportedConstruct', 'relevant', 'clusterId', 'managedByCfn'],
  wrapLines: false,
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled = false,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: 'Page size',
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: 'Wrap lines',
      description: 'Check to see all the text and wrap the lines',
    }}
    visibleContentPreference={{
      title: 'Select visible columns',
      options: visibleContentOptions,
    }}
  />
);
export const getServerHeaderCounterText = (totalCount, selectedItems) => (selectedItems && selectedItems.length > 0 ? `(${selectedItems.length}/${totalCount}+)` : `(${totalCount}+)`);

const getHeaderCounterText = (items = [], selectedItems = []) => (selectedItems && selectedItems.length > 0 ? `(${selectedItems.length}/${items.length})` : `(${items.length})`);

function getCounter(props) {
  if (props.counter) {
    return props.counter;
  }
  if (!props.totalItems) {
    return null;
  }
  if (props.serverSide) {
    return getServerHeaderCounterText(props.totalItems, props.selectedItems);
  }
  return getHeaderCounterText(props.totalItems, props.selectedItems);
}

export const TableHeader = (props) => (
  <Header
    variant={props.variant}
    counter={getCounter(props)}
    description={props.description}
    actions={props.actionButtons}
  >
    {props.title}
  </Header>
);
