import {
  Checkbox,
  CheckboxProps,
  Container,
  Header,
  Link,
} from '@amzn/awsui-components-react-v3';
import React from 'react';
import { ItemType } from './Types';

type AlkimiaResourceSelectionProps = {
  allTableItems: ItemType[],
  clusteredListCheckState: boolean[],
  clusters: Map<string, ItemType[]>,
  selectAllClusters: boolean,
  setClusteredListCheckState: React.Dispatch<React.SetStateAction<boolean[]>>,
  setIsToolsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setTableItems: React.Dispatch<React.SetStateAction<any>>,
  setSelectAllClusters: React.Dispatch<React.SetStateAction<boolean>>,
}

/**
 * Child component of ResourceSelectionComponent that displays side navigation for clustering
 * @param allTableItems - data to reset all the items in the table component
 * @param clusteredListCheckState - manages items that have be "checked" in left side nav
 * @param clusters - map containing clusterId and values are the items
 * @param selectAllClusters - boolean value to select all the clusters in side-nav
 * @returns TableComponent for ResourceSelection Page
 */
function ResourceGroupingSideNav({
  allTableItems,
  clusteredListCheckState,
  clusters,
  selectAllClusters,
  setClusteredListCheckState,
  setIsToolsOpen,
  setTableItems,
  setSelectAllClusters,
}: AlkimiaResourceSelectionProps) {
  /**
   * Handles checked for clustered list
   * @param detail detail custom data event that handles checkbox
   * @param clusteredListIndex index while traversing through list of clusters
   */
  const handleCheckedClusteredResources = (detail: CheckboxProps.ChangeDetail, clusteredListIndex: number) => {
    const indexList: number[] = []; // indexs to get resources from clusters map. clusters state is assumed to be in same order as clusteredList clusteredListCheckState
    let itemList: ItemType[] = [];
    const updatedCheckedState: boolean[] = clusteredListCheckState.map((item, index) => {
      // select all clusters condition
      if (clusteredListIndex < 0) {
        if (detail.checked) {
          indexList.push(index);
        }
        return detail.checked;
      }

      if (index === clusteredListIndex) {
        if (detail.checked) {
          indexList.push(index);
        }
        return detail.checked;
      }

      if (item === true) {
        indexList.push(index);
      }

      return item;
    });

    // no index in list means no clusters are being checked
    if (indexList.length > 0) {
      indexList.forEach((clusterIndex) => {
        const clusterName: string = Array.from(clusters.keys())[clusterIndex];
        const resources: ItemType[] = clusters.get(clusterName)!;
        itemList = itemList.concat(resources);
      });
      setTableItems(itemList);
    } else {
      setTableItems(allTableItems);
    }
    setClusteredListCheckState(updatedCheckedState);
  };
  return (
    <Container
      header={(
        <Header
          variant="h2"
        >
          Resources Grouped by Dependency
          <div>
            <Link
              onFollow={() => {
                setIsToolsOpen(true);
              }}
              href="#/"
            >
              More Info
            </Link>
          </div>
        </Header>
      )}
    >
      <Checkbox
        checked={selectAllClusters}
        onChange={({ detail }) => {
          handleCheckedClusteredResources(detail, -1);
          setSelectAllClusters(detail.checked);
        }}
      >
        <div>
          <b>
            Select All
          </b>
        </div>
      </Checkbox>
      {[...clusters].map((resources, index) => (
        <Container>
          <Checkbox
            checked={clusteredListCheckState[index]}
            onChange={({ detail }) => handleCheckedClusteredResources(detail, index)}
          >
            <div>{`${resources[0]}_stack`}</div>
            <div>{`(${resources[1].length} resources)`}</div>
          </Checkbox>
        </Container>
      ))}
    </Container>
  );
}

export const ResourceGroupingSideNavComponent = ResourceGroupingSideNav;
