import { ConstructType, cdkConstructType } from '@amzn/alkimia-model';
import { CONSTRUCT_OPTIONS, MANAGED_BY_CLOUDFORMATION_OPTIONS, RELEVANT_OPTIONS } from '../../../utils/Constants';

export type ItemType = {
  name: string,
  selected: boolean,
  type: string,
  service: string,
  supportedConstruct: cdkConstructType,
  managedByCfn?: boolean,
  relevant: boolean,
  clusterId: string,
  verifiedByAlkimia?: boolean,
}

export type EmptyStateProps = {
  title: string,
  subtitle: string,
  action: any,
}

export type SelectedOption = {
  label: string;
  value: string;
}

export type ConstructOption = {
  label: string;
  value: ConstructType;
}

export const defaultConstruct = CONSTRUCT_OPTIONS[0];
export const defaultRelevantOption = RELEVANT_OPTIONS[0];
export const defaultManagedByCloudFormation = MANAGED_BY_CLOUDFORMATION_OPTIONS[0];

export const stackGroupingTypeOptions: SelectedOption[] = [
  { label: 'Dependency clustering', value: 'cluster' },
  { label: 'Single stack', value: 'single' },
];
