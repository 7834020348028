import React, { useState } from 'react';
import { Alert } from '@amzn/awsui-components-react-v3';
import { CognitoToken, CookieStorage } from 'amazon-cognito-auth-ts';
import {
  CALL_BACK_URL,
  COGNITO_CLIENT_ID,
  COGNITO_USER_POOL_ID,
  COGNITO_WEB_DOMAIN,
  DeploymentStage,
  LAMBDA_API_URL,
} from './Constants';
import { APIError } from '../api/models/APIError';

const LOCALHOST_TARGET_STAGE: DeploymentStage = DeploymentStage.BETA;

export function getStage(): DeploymentStage {
  const location = window.location.href.toString();

  if (location.includes('https://beta.alkimia.tools.amazon.dev/')) {
    return DeploymentStage.BETA;
  }
  if (location.includes('https://gamma.alkimia.tools.amazon.dev/')) {
    return DeploymentStage.GAMMA;
  }
  if (location.includes('https://prod.alkimia.tools.amazon.dev/')) {
    return DeploymentStage.PROD;
  }
  if (location.includes('localhost')) {
    return DeploymentStage.LOCAL;
  }
  return DeploymentStage.BETA;
}

export const getLambdaApiUrl = (): string => {
  const stage: DeploymentStage = getStage();
  if (stage === DeploymentStage.LOCAL) {
    return LAMBDA_API_URL[LOCALHOST_TARGET_STAGE];
  }
  return LAMBDA_API_URL[stage];
};

export function constructQueryString(params: { [key: string]: string | number }): string {
  return Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');
}

export const getStandardHeader = (token: CognitoToken): Record<string, string> => (
  {
    Accept: 'application/json',
    Authorization: `${token}`,
  }
);

export const processApiError = (call: string, error: any, plain?: true) => {
  if (error.response) {
    if (plain) {
      throw APIError.fromAxiosError(error, call, error.response.data.message);
    }
    // Request made and server responded
    throw APIError.fromAxiosError(error, call,
      `${call} request failed - ${error}.
         Message: "${error.response.data.message}"
         RequestID: ${error.response.headers['x-amzn-requestid']}
         ErrorType: ${error.response.headers['x-amzn-errortype']}
         TimeStamp: ${error.response.headers.date}`);
  } else if (error.request) {
    // The request was made but no response was received
    throw APIError.fromAxiosError(error, call, `${call} request failed: ${error}. 
    The request was made but no response was received. Request: ${error.request}`);
  } else {
    // Something happened in setting up the request that triggered an Error
    throw APIError.fromAxiosError(error, call, `${call} request failed: ${error}. 
    Things went wrong in setting up the request.`);
  }
};

const CURR_STAGE = getStage();
export const COGNITO_AUTH_CLIENT = {
  AppWebDomain: COGNITO_WEB_DOMAIN[CURR_STAGE],
  ClientId: COGNITO_CLIENT_ID[CURR_STAGE],
  RedirectUriSignIn: CALL_BACK_URL[CURR_STAGE],
  RedirectUriSignOut: CALL_BACK_URL[CURR_STAGE],
  TokenScopesArray: ['openid'],
  UserPoolId: COGNITO_USER_POOL_ID[CURR_STAGE],
  Storage: new CookieStorage(),
};

export const displayErrorMessage = (errorMessages: string[], contextMessage?: string) => {
  if (errorMessages.length > 0) {
    const [isAlertVisible, setIsAlertVisible] = useState(true);
    return (
      <Alert
        visible={isAlertVisible}
        dismissible
        type="warning"
        onDismiss={() => {
          setIsAlertVisible(false);
        }}
      >
        {errorMessages.map((msg) => <>
          {msg}
          <br />
        </>)}
        {contextMessage === undefined ? <></> : <b>{contextMessage}</b>}
      </Alert>
    );
  }
  return (null);
};
